@use './variables.scss' as customVariables;
// @import '~firebaseui/dist/firebaseui.css';
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hospital-passport-portal-primary: mat-palette(customVariables.$hp-palette);
$hospital-passport-portal-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hospital-passport-portal-warn: mat-palette($mat-red);

$hospital-passport-portal-green: mat-palette(customVariables.$hp-green);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hospital-passport-portal-theme: mat-light-theme(
    (
        color: (
            primary: $hospital-passport-portal-primary,
            accent: $hospital-passport-portal-accent,
            warn: $hospital-passport-portal-warn
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($hospital-passport-portal-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
    color-adjust: exact !important;                 /*Firefox*/
}

.successCssSnackBar {
    background-color: #28a745;
    color: white;
    margin: auto;
    text-align: center;
}

p.error-message {
    color: red;
}

.failureCssSnackBar {
    background-color: darkred;
    color: white;
    margin: auto;
    text-align: center;
}

.is-active {
    background: #2e7fdb !important;
    background-color: #2e7fdb !important;
    color: white !important;
}

.table-container {
    width: 100%;
    padding: 50px;
    padding-top: 20px;
    box-sizing: border-box;

    .table-outer {
        border-radius:8px;
        overflow:hidden;
    }

    table {
        width: 100%;
        .row:hover {
            background: whitesmoke;
        }
        .row:active {
            background: #efefef;
        }
    }
    mat-paginator {
        width: 100%;
    }
}

.table-filter-banner {
    width: 100%;
    box-sizing: border-box;
    padding-left: 50px;
    padding-top: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .search-box-container {
        width: 400px;

        .search-box {
            width: 400px;

        }
    }
}
